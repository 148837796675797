import * as React from 'react'
import Layout from '../components/layout'
import { StaticImage } from 'gatsby-plugin-image'

const IndexPage = () => {
  return (
    <main>
      {process.env.NODE_ENV === "development" ? (
        <Layout pageTitle="Home Page">
          <p>TEST</p>
          <StaticImage
            alt="Clifford, a reddish-brown pitbull, posing on a couch and looking stoically at the camera"
            src="https://pbs.twimg.com/media/E1oMV3QVgAIr1NT?format=jpg&name=large"
          />
        </Layout>
      ) : null}

    {process.env.NODE_ENV === "production" ? (
      <p>under construction</p>
    ) : null}
    </main>
  )
}

export const Head = () => <title>Home Page</title>

export default IndexPage
